import { useNavigate } from "react-router-dom";
import MyButton from "./MyButton";

const DirectorCard = (props) => {
  const { data, setDeleteView } = props;
  const navigate = useNavigate();

  return (
    <section className="project_card">
      <div>
        <h3 className="title">{data.nombre}</h3>
        <p style={{fontSize:"0.7rem"}}>{data.hasProjects==0 && <>Sin proyectos para la web, solo home</>}</p>
      </div>
      <MyButton
        text="PROJECTS"
        className="action"
        onClick={() => {
          navigate(`/projects/${data.director_id}`);
        }}
      />
     
        <div className="edit_section">
          <MyButton
            text="EDIT"
            className="edit"
            onClick={() => {
              navigate(`/edit_director/${data.director_id}`);
            }}
          />
          <MyButton
            text="DELETE"
            className="logout"
            onClick={() => {
              setDeleteView({active:true, id:data.director_id});
            }}
            
          />
        </div>
   
    </section>
  );
};

export default DirectorCard;
