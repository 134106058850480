import { useState } from "react";
import { Divider } from "@mui/material";
import AddNewProject from "./AddNewProject";
import MyButton from "./MyButton";
import { useNavigate } from "react-router-dom";
import DirectorCard from "./DirectorCard";

const ProjectsList = (props) => {
  const { projects, setDeleteView } = props;
  const navigate = useNavigate();
  return (
    <section className="project_list">
      <section className="project_list-header">
        <h2>Directores</h2>
        <MyButton
          text="Add Director"
          className="add"
          onClick={() => {
            navigate("/add_director");
          }}
        />
      </section>
      <Divider />
      {
        <section className="list">
          {projects.map((project) => {
            return <DirectorCard data={project} key={project.director_id} setDeleteView={setDeleteView} />;
          })}
        </section>
      }
      <Divider />
    </section>
  );
};

export default ProjectsList;
