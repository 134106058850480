import { TextField } from "@mui/material";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";

const NombresAssets = () => {
  return (
    <>
    <TopBar/>
      <Navbar />
      <section className="create shape" style={{gridRow:"2/4"}}>
        <>
          <h3>Nombres de assets en la web</h3>
          <h4>Generales</h4>
          <TextField
            label="Loader"
            variant="outlined"
            className="input_form"
            value="loader.gif"
          />
          <TextField
            label="Logo"
            variant="outlined"
            className="input_form"
            value="puerco-logo.png"
          />
          <TextField
            label="Vimeo"
            variant="outlined"
            className="input_form"
            value="vimeo.png o vimeo_black.png"
          />
          <TextField
            label="Instagram"
            variant="outlined"
            className="input_form"
            value="ig.png o ig_black.png"
          />
          <TextField
            label="Instagram"
            variant="outlined"
            className="input_form"
            value="ig.png o ig_black.png"
          />
          <TextField
            label="Botón de play"
            variant="outlined"
            className="input_form"
            value="play.png"
          />
          
          <h4>Home</h4>
          <TextField
            label="Video Home Desktop"
            variant="outlined"
            className="input_form"
            value="homebg.mp4"
          />
          <TextField
            label="Video Home Mobile"
            variant="outlined"
            className="input_form"
            value="homebg-mobile.mp4"
          />
          <h4>Service</h4>
          <TextField
            label="Banner"
            variant="outlined"
            className="input_form"
            value="bg-service.png"
          />
          <TextField
            label="Banner Mobile"
            variant="outlined"
            className="input_form"
            value="bg-service_mobile.png"
          />
          <TextField
            label="Video"
            variant="outlined"
            className="input_form"
            value="service_loop.mp4"
          />
          <h4>Brutal</h4>
          <TextField
            label="Background Desktop"
            variant="outlined"
            className="input_form"
            value="bg-brutal.webp"
          />
          <TextField
            label="Background Mobile"
            variant="outlined"
            className="input_form"
            value="bg-brutal_mob.webp"
          />
          <TextField
            label="Brutal Logo"
            variant="outlined"
            className="input_form"
            value="brutal_logo.png"
          />
          <TextField
            label="Banner"
            variant="outlined"
            className="input_form"
            value="brutal_banner.png"
          />
          <TextField
            label="Loop"
            variant="outlined"
            className="input_form"
            value="brutal.mp4"
          />
          <TextField
            label="Link a Work"
            variant="outlined"
            className="input_form"
            value="link_work.svg"
          />



          <h4>About</h4>
          <p>No tiene assets</p>
        </>
      </section>
    </>
  );
};

export default NombresAssets;
