import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useQuery from "../hooks/useQuery";

const EditEmployee = (props) => {
  const [projectData, setProjectData] = useState({
    titulo: "",
    cliente: "",
    loop_mp4: "",
    loop_mobile: "",
    video_mp4: "",
    portada: "",
    destacado: null,
  });

  const { id } = useParams();
  const { setEdit, setError, projectId } = props;
  const { getData, putData } = useQuery();

  const editProject = async () => {
    if (
      projectData.titulo === "" ||
      projectData.cliente === "" ||
      projectData.video_mp4 === "" ||
      projectData.loop_mp4 === "" ||
      projectData.loop_mobile === "" ||
      projectData.portada === ""
    ) {
      setError({
        status: true,
        message: "You must fill all the fields",
        severity: "error",
      });
      return;
    }
    const data = {
      endpoint: "actualizar-proyecto",
      proyecto_id : parseInt(projectId),
      destacado: projectData.destacado,
      titulo: projectData.titulo,
      cliente: projectData.cliente,
      director_id:parseInt(id),
      loop_mp4: projectData.loop_mp4,
      loop_mobile: projectData.loop_mobile,
      video_mp4: projectData.video_mp4,
      portada: projectData.portada,
    };
    const post = await putData(``, data, localStorage.token);
    setEdit(false);
  };
  const getProjectInfo = async () => {
    const info = await getData(
      `?endpoint=proyecto-id&id=${projectId}`,
      localStorage.token
    );
    const { titulo, cliente, video_mp4, loop_mp4, loop_mobile, portada,destacado } = info.data.data;
    const destacadoNew=destacado==1;

    setProjectData({
      titulo,
      cliente,
      video_mp4,
      loop_mp4,
      loop_mobile,
      portada,
      destacado : destacadoNew
    });
  };
  useEffect(() => {
    getProjectInfo();
  }, []);

  return (
    <>
      <section className="create create_complete">
        <h3>Edit Project Info</h3>
        <p>Please complete or edit the rest of the fields</p>
        <TextField
          className="input_form"
          id="titulo"
          label="Titulo"
          variant="outlined"
          value={projectData.titulo}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              titulo: e.target.value,
            });
          }}
        />
        <TextField
          className="input_form"
          id="cliente"
          label="Cliente"
          variant="outlined"
          value={projectData.cliente}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              cliente: e.target.value,
            });
          }}
        />
        <TextField
          className="input_form"
          id="video"
          label="Video_mp4"
          variant="outlined"
          value={projectData.video_mp4}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              video_mp4: e.target.value,
            });
          }}
        />
        <TextField
          className="input_form"
          id="loop_desktop"
          label="Loop Desktop"
          variant="outlined"
          value={projectData.loop_mp4}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              loop_mp4: e.target.value,
            });
          }}
        />
        <TextField
          className="input_form"
          id="loop_mobile"
          label="Loop Mobile"
          variant="outlined"
          value={projectData.loop_mobile}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              loop_mobile: e.target.value,
            });
          }}
        />
        <TextField
          className="input_form"
          id="video"
          label="Portada"
          variant="outlined"
          value={projectData.portada}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              portada: e.target.value,
            });
          }}
        />
        <div>
          <label> Proyecto destacado</label>
          <input
            type="checkbox"
            name="destacado"
            id=""
            checked={projectData.destacado}
            onChange={(e) => {
              setProjectData({
                ...projectData,
                destacado: e.target.checked,
              });
            }}
          />
        </div>

        <Button variant="contained" onClick={editProject}>
          EDIT
        </Button>
      </section>
    </>
  );
};
export default EditEmployee;
