import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";
import UserContext from "../context/UserContext";
const Navbar = () => {
  const getCurrentDimension = () => {
    return { width: window.innerWidth };
  };
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [expand, setExpand] = useState(false);
  const { permission } = useContext(UserContext);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  return (
    <nav
      className={`${screenSize.width < 1200 ? "mini" : ""} ${
        expand ? "expanded" : ""
      }`}
    >
      {!expand ? (
        <MenuOpenIcon
          className="nav_button open"
          onClick={() => {
            setExpand(true);
          }}
        />
      ) : (
        <CloseIcon
          className="nav_button"
          onClick={() => {
            setExpand(false);
          }}
        />
      )}
      <ul className="main_ul">
        <li>
          <Link to="/">Directores</Link>
        </li>
        <li>
          <Link to="/trabajos_home">Trabajos Home</Link>
        </li>
        <li>
          <Link to="/assets">Assets</Link>
        </li>
        <li>Edit</li>
        <li>
          <ul className="secondary_ul">
            <li>
              <Link
                to={permission.permissions === "generic" ? "/" : "/create"}
                className={permission.permissions === "generic" ? "locked" : ""}
              >
                Add Director
              </Link>
            </li>
            <li>
              <Link
                to={"/create_team"}
                className={permission.permissions === "generic" ? "locked" : ""}
              >
                Team Members
              </Link>
            </li>
            <li>
              <Link
                to={"/create_contacto"}
                className={permission.permissions === "generic" ? "locked" : ""}
              >
                Contactos
              </Link>
            </li>
            <li>
              <Link
                to={"/about"}
                className={permission.permissions === "generic" ? "locked" : ""}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to={"/service"}
                className={permission.permissions === "generic" ? "locked" : ""}
              >
                Service
              </Link>
            </li>
            <li>
              <Link
                to={"/brutal"}
                className={permission.permissions === "generic" ? "locked" : ""}
              >
                Brutal
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
