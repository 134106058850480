import {  Routes, Route } from "react-router-dom";
import Home from "../views/Home";
import Project from "../views/Project";
import Register from "../views/Register";
import CreateUser from "../views/Create";
import DeleteUser from "../views/DeleteUser";
import AddDirector from "../components/AddDirector";
import EditDirector from "../views/EditDirector";
import HomeEdit from "../views/HomeEdit";
import AddTeamMember from "../views/AddTeamMember";
import AddContacto from "../views/AddContacto";
import TextosAbout from "../views/TextosAbout";
import TextosService from "../views/TextosService";
import TextosBrutal from "../views/TextosBrutal";
import NombresAssets from "../views/NombresAssets";

const AppRouter = () => {
  return (
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/register" element={<Register />}/>
            <Route path="/trabajos_home" element={<HomeEdit />}/>
            <Route path="/add_director" element={<AddDirector />}/>
            <Route path="/edit_director/:id" element={<EditDirector />}/>
            <Route path="/create" element={<CreateUser />}/>
            <Route path="/create_team" element={<AddTeamMember />}/>
            <Route path="/create_contacto" element={<AddContacto />}/>
            <Route path="/delete_user" element={<DeleteUser />}/>
            <Route path="/about" element={<TextosAbout />}/>
            <Route path="/service" element={<TextosService />}/>
            <Route path="/brutal" element={<TextosBrutal />}/>
            <Route path="/assets" element={<NombresAssets />}/>

            <Route path="/projects/:id" element={<Project/>} />
        </Routes>
  )
}

export default AppRouter