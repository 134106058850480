import { useNavigate, useParams } from "react-router-dom";

import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import { Alert, IconButton } from "@mui/material";

import EmployeesGrid from "../components/EmployeesGrid";
import useQuery from "../hooks/useQuery";
import TopBar from "../components/TopBar";
import Skeleton from "@mui/material/Skeleton";
import MyButton from "../components/MyButton";

import CloseIcon from "@mui/icons-material/Close";
import AddHomeProject from "../components/AddHomeProject";
import EditHomeProject from "../components/EditHomeProject";
import ProjectoHomeGrid from "../components/ProjectoHomeGrid";

const HomeEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState();
  const [directorData, setDirectorData] = useState({});
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  const { getData } = useQuery();

  const getInitialData = async () => {
    setLoading(true);
    const data = await getData(
      `?endpoint=trabajos-home`,
      localStorage.token
    );
    const directorData = await getData(
      `?endpoint=director-id&id=${id}`,
      localStorage.token
    );
    setDirectorData(directorData.data.data);
    setProjects(data.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);
  useEffect(() => {
    getInitialData();
  }, [edit, add]);

  return (
    <>
      <TopBar />
      <Navbar />

      <section className="project_body">
        <section className="project_header ">
          <h1 className="project_header-name">
            {loading ? (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            ) : (
              <> Trabajos de home</>
            )}
          </h1>
        </section>

        <MyButton
          className={`add ${add || edit ? "hidden" : ""} employee`}
          text="Add Project"
          onClick={() => {
            setError({
              status: false,
              message: "",
              severity: "",
            });
            setAdd(true);
          }}
        />
      </section>
      {error.status && (
        <Alert
          className={`project_alert ${
            error.severity === "warning" ? "warning" : ""
          } ${error.severity === "warning" && add ? "hidden" : ""}`}
          severity={error.severity}
          sx={{ marginTop: "1rem" }}
          action={
            error.severity === "warning" && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setError({
                    status: false,
                    message: "",
                    severity: "",
                  });
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
        >
          {error.message}
        </Alert>
      )}
      <>
        {add && (
          <AddHomeProject
            setAdd={setAdd}
            projects={projects}
            setProjects={setProjects}
            setError={setError}
          />
        )}
        {edit && (
          <EditHomeProject
            setEdit={setEdit}
            projectId={projectId}
            setError={setError}
          />
        )}
      </>

      {!add && !edit && projects && (
        <section className="show_employees">
          <>
            <ProjectoHomeGrid
              add={add}
              projects={projects}
              setEdit={setEdit}
              setProjectId={setProjectId}
              setError={setError}
              loading={loading}
              getInitialData={getInitialData}
              setLoading={setLoading}
              clase="info-trabajo_home"
            />
          </>
        </section>
      )}
    </>
  );
};

export default HomeEdit;
