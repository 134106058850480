import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { employeeTypes } from "../helpers/data";
import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import SignaturePad from "./SignaturePad";
import { checkStartTime, dateToAMD, getDate } from "../helpers/general";
import useQuery from "../hooks/useQuery";
import MyButton from "./MyButton";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { InputReducer } from "../reducers/InputReducer";

const AddHomeProject = (props) => {
  const [sending, setSending] = useState(false);
  const { setAdd } = props;
  const { getData, postData } = useQuery();

  const { setError } = props;
  const { id } = useParams();
  const [directores, setDirectores] = useState([]);

  const addProject = async () => {
    if (
      inputData.titulo === "" ||
      inputData.cliente === "" ||
      inputData.video_mp4 === "" ||
      inputData.loop_mp4 === "" ||
      inputData.loop_mobile === "" ||
      inputData.portada === "" ||
      inputData.director_id === ""
    ) {
      setError({
        status: true,
        message: "You must fill all the fields",
        severity: "error",
      });
      return;
    }
    setSending(true);

    const directorEncontrado = directores.find(
      (director) => director.director_id === inputData.director_id
    );
    const director = directorEncontrado.nombre;
    const projectData = {
      director_id: parseInt(inputData.director_id),
      director,
      endpoint: "agregar-home",
      titulo: inputData.titulo,
      cliente: inputData.cliente,
      video_mp4: inputData.video_mp4,
      loop_mp4: inputData.loop_mp4,
      loop_mobile: inputData.loop_mobile,
      portada: inputData.portada,
    };

    const data = await postData("", projectData, localStorage.token);

    if (data.data.status === 201) {
      setError({ error: false });
      setSending(false);
      setAdd(false);
      return;
    }

    setError({ status: true, message: "Error inesperado", severity: "error" });
    setSending(false);
  };

  const [inputData, inputDispatch] = useReducer(InputReducer, {
    titulo: "",
    cliente: "",
    video_mp4: "",
    loop_mp4: "",
    loop_mobile: "",
    portada: "",
    director_id: "",
    director: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };

  const getDirectorsData = async () => {
    const directors = await getData("?endpoint=directores");
    setDirectores(directors.data.data);
  };
  useEffect(() => {
    getDirectorsData();
  }, []);
  return (
    <>
      <section className="create shape">
        <>
          <h3>Agregar proyecto a home</h3>
          <p>Please fill all the fields below</p>
          <TextField
            id="Titulo"
            label="Titulo"
            variant="outlined"
            className="input_form"
            name="titulo"
            onChange={handleInputChange}
            value={inputData.titulo || ""}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Director</InputLabel>
            <Select
              labelId="director-id"
              name="director_id"
              label="Director"
              onChange={handleInputChange}
              value={inputData.director_id || ""}
            >
              {directores?.map((director) => {
                return (
                  <MenuItem
                    key={director.director_id}
                    value={director.director_id}
                  >
                    {director.nombre}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="cliente"
            label="Cliente"
            variant="outlined"
            className="input_form"
            name="cliente"
            onChange={handleInputChange}
            value={inputData.cliente || ""}
          />
          <TextField
            id="video_mp4"
            label="Video_mp4"
            variant="outlined"
            className="input_form"
            name="video_mp4"
            onChange={handleInputChange}
            value={inputData.video_mp4 || ""}
          />
          <TextField
            id="loop_mp4"
            label="Loop Desktop"
            variant="outlined"
            className="input_form"
            name="loop_mp4"
            onChange={handleInputChange}
            value={inputData.loop_mp4 || ""}
          />
          <TextField
            id="loop_mobile"
            label="Loop Mobile"
            variant="outlined"
            className="input_form"
            name="loop_mobile"
            onChange={handleInputChange}
            value={inputData.loop_mobile || ""}
          />
          <TextField
            id="portada"
            label="Portada"
            variant="outlined"
            className="input_form"
            name="portada"
            onChange={handleInputChange}
            value={inputData.portada || ""}
          />

          {!sending ? (
            <MyButton text="Set" onClick={addProject} className="action" />
          ) : (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          )}
        </>
      </section>
    </>
  );
};

export default AddHomeProject;
