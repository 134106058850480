import { useEffect, useReducer, useState } from "react";
import useQuery from "../hooks/useQuery";
import { Alert, CircularProgress, TextField } from "@mui/material";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import MyButton from "../components/MyButton";
import { InputReducer } from "../reducers/InputReducer";

const CreateUser = () => {
  const [sending, setSending] = useState(false);

  const initialState = {
    nombre: "",
    hasProjects: 0,
  };

  const [inputData, inputDispatch] = useReducer(InputReducer, initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };

  const [error, setError] = useState({ status: false, message: "" });
  const { getData, postData } = useQuery();

  const register = async () => {
    if (inputData.nombre === "") {
      setError({
        status: true,
        message: "You must enter all the fields",
        severity: "error",
      });
      return;
    }
    const isPuerco = inputData.hasProjects ? 1 : 0;
    setSending(true);
    const data = {
      nombre: inputData.nombre,
      hasProjects: isPuerco,
      endpoint: "agregar-director",
    };
    const response = await postData("", data, localStorage.token);
    if (response.data.status === 201) {
      setError({ status: true, message: "User created", severity: "success" });
      setSending(false);
    }
  };

  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content">
        <h2 className="ml-20">Agregar Director</h2>
        <p className="ml-20">Please fill all the required information</p>
        <section className="create create_project shape">
          <TextField
            className="input_form"
            id="nombre"
            label="Nombre"
            variant="outlined"
            name="nombre"
            onChange={handleInputChange}
            value={inputData.nombre}
          />
          <div style={{ margin: "20px 0" }}>
            <label> Tiene proyectos en Puerco</label>
            <input
              type="checkbox"
              name="destacado"
              id=""
              value={inputData.hasProjects}
              onChange={(e) => {
                inputDispatch({
                  type: "CHANGE_FIELD",
                  fieldName: "hasProjects",
                  fieldValue: e.target.checked,
                });
              }}
            />
          </div>

          {!sending ? (
            <MyButton
              onClick={register}
              text="ADD DIRECTOR"
              className="action"
            />
          ) : (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          )}
          {error.status && (
            <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
              {error.message}
            </Alert>
          )}
        </section>

     
      </section>
    </>
  );
};

export default CreateUser;
