import { createContext, useEffect, useReducer } from "react";
import { AuthReducer } from "../reducers/AuthReducer";
import { authTypes } from "../types/AuthTypes";

const AuthContext = createContext();

const init = () => {
  return (
    JSON.parse(localStorage.getItem("userLogged")) || { userLogged: false }
  );
};

const AuthProvider = ({ children }) => {

  const [logged, dispatch] = useReducer(AuthReducer, {}, init);

 /*  const removeUser = () => {
    dispatch({ type: authTypes.logout });
    localStorage.removeItem("token");
  }; */

  
  useEffect(() => {
    localStorage.setItem("userLogged", JSON.stringify(logged));
   
  }, [logged]);

  return (
    <AuthContext.Provider value={{ logged, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
export { AuthProvider };
export default AuthContext;
