import {  useEffect, useReducer, useState } from "react";
import useQuery from "../hooks/useQuery";
import { Alert, CircularProgress, TextField, TextareaAutosize } from "@mui/material";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import MyButton from "../components/MyButton";
import { InputReducer } from "../reducers/InputReducer";

const TextosBrutal = () => {
  const [sending, setSending] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const initialState = {
    p_1: "",
    linkto: "",
    id: "",
  };

  const [inputData, inputDispatch] = useReducer(InputReducer, initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };
  const [error, setError] = useState({ status: false, message: "" });
  const { postData, getData, putData } = useQuery();

  const register = async () => {
    if (inputData.p_1 === "" || inputData.linkto === "") {
      setError({
        status: true,
        message: "You must enter all the fields",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const data = {
      p_1: inputData.p_1,
      linkto: inputData.linkto,
      endpoint: "textos",
      seccion: "brutal",
    };
    const response = await postData("", data, localStorage.token);
    if (response.data.status === 201) {
      setError({ status: true, message: "Agregado", severity: "success" });
      setSending(false);
    }
  };

  const getInitialData = async () => {
    const data = await getData(`?endpoint=texto-brutal`, localStorage.token);
    console.log(data.data.data)
    if (data.data.data.length > 0) {
      setEditMode(true);
      inputDispatch({
        type: "CHANGE_FIELD",
        fieldName: "p_1",
        fieldValue: data.data.data[0].p_1,
      });
      inputDispatch({
        type: "CHANGE_FIELD",
        fieldName: "linkto",
        fieldValue: data.data.data[0].linkto,
      });
      inputDispatch({
        type: "CHANGE_FIELD",
        fieldName: "id",
        fieldValue: data.data.data[0].id,
      });
    }
  };

  const update = async () => {
    if (inputData.p_1 === "" || inputData.linkto === "") {
      setError({
        status: true,
        message: "You must enter all the fields",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const data = {
      p_1: inputData.p_1,
      linkto: inputData.linkto,
      id: parseInt(inputData.id),
      endpoint: "actualizar-textos",
      seccion: "brutal",
    };
    const response = await putData("", data, localStorage.token);
    if (response.data.status === 201) {
      setError({ status: true, message: "Agregado", severity: "success" });
      setSending(false);
    }
    if (response.error === true) {
        setError({ status: true, message: "Error", severity: "error" });
      setSending(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);
  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content">
        <h2 className="ml-20">Textos de Brutal</h2>
        <p className="ml-20">Please fill all the required information</p>
        <section className="create create_project shape">
          <TextareaAutosize
            className="input_form"
            id="p_1"
            label="Parrafo 1"
            variant="outlined"
            placeholder="Parrafo 1"
            name="p_1"
            onChange={handleInputChange}
            value={inputData.p_1}
            minRows={5}
          />
          <TextField
            className="input_form"
            id="linkto"
            label="Link to IG de brutal"
            variant="outlined"
            name="linkto"
            onChange={handleInputChange}
            value={inputData.linkto}
          />

          {!sending ? (
            <MyButton
              onClick={editMode ? update : register}
              text={editMode ? "EDIT" : "ADD"}
              className="action"
            />
          ) : (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          )}
          {error.status && (
            <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
              {error.message}
            </Alert>
          )}
        </section>
      </section>
    </>
  );
};

export default TextosBrutal;
