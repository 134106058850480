import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useQuery from "../hooks/useQuery";

const EditHomeProject = (props) => {
  const [directores, setDirectores] = useState([]);

  const [projectData, setProjectData] = useState({
    titulo: "",
    cliente: "",
    director: "",
    director_id : null,
    loop_mp4: "",
    loop_mobile: "",
    video_mp4: "",
    portada: "",
  });

  const { id } = useParams();
  const { setEdit, setError, projectId } = props;
  const { getData, putData } = useQuery();

  const editProject = async () => {
    if (
      projectData.titulo === "" ||
      projectData.cliente === "" ||
      projectData.video_mp4 === "" ||
      projectData.director === "" ||
      projectData.loop_mp4 === "" ||
      projectData.loop_mobile === "" ||
      projectData.portada === ""
    ) {
      setError({
        status: true,
        message: "You must fill all the fields",
        severity: "error",
      });
      return;
    }
    const directorEncontrado = directores.find(
      (director) => director.director_id === projectData.director_id
    );
    const director = directorEncontrado.nombre;
    const data = {
      endpoint: "actualizar-trabajo-home",
      trabajo_id: parseInt(projectId),
      titulo: projectData.titulo,
      cliente: projectData.cliente,
      director,
      director_id: projectData.director_id,
      loop_mp4: projectData.loop_mp4,
      loop_mobile: projectData.loop_mobile,
      video_mp4: projectData.video_mp4,
      portada: projectData.portada,
    };

    const post = await putData(``, data, localStorage.token);
    setEdit(false);
  };

  const getProjectInfo = async () => {
    const directors = await getData("?endpoint=directores");
    setDirectores(directors.data.data);
    const info = await getData(
      `?endpoint=trabajo-home-id&id=${projectId}`,
      localStorage.token
    );
    const { titulo, cliente, director, video_mp4, loop_mp4, loop_mobile, portada, director_id } =
      info.data.data;
    setProjectData({
      titulo,
      cliente,
      director,
      director_id,
      video_mp4,
      loop_mp4,
      loop_mobile,
      portada,
    });
  };

  useEffect(() => {
    getProjectInfo();
  }, []);

  return (
    <>
      <section className="create create_complete">
        <h3>Edit Project Info </h3>
        <p>Please complete or edit the rest of the fields</p>
        <TextField
          className="input_form"
          id="titulo"
          label="Titulo"
          variant="outlined"
          value={projectData.titulo}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              titulo: e.target.value,
            });
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Director</InputLabel>
          <Select
            labelId="director-id"
            name="director_id"
            label="Director"
            onChange={(e) => {
              setProjectData({
                ...projectData,
                director_id: e.target.value,
              });
            }}
            value={projectData.director_id || ""}
          >
            {directores?.map((director) => {
              return (
                <MenuItem
                  key={director.director_id}
                  value={director.director_id}
                >
                  {director.nombre}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          className="input_form"
          id="cliente"
          label="Cliente"
          variant="outlined"
          value={projectData.cliente || ""}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              cliente: e.target.value,
            });
          }}
        />
        <TextField
          className="input_form"
          id="video"
          label="Video_mp4"
          variant="outlined"
          value={projectData.video_mp4}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              video_mp4: e.target.value,
            });
          }}
        />
        <TextField
          className="input_form"
          id="video"
          label="Loop Desktop"
          variant="outlined"
          value={projectData.loop_mp4}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              loop_mp4: e.target.value,
            });
          }}
        />
        <TextField
          className="input_form"
          id="video"
          label="Loop Mobile"
          variant="outlined"
          value={projectData.loop_mobile}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              loop_mobile: e.target.value,
            });
          }}
        />
        <TextField
          className="input_form"
          id="video"
          label="Portada"
          variant="outlined"
          value={projectData.portada}
          onChange={(e) => {
            setProjectData({
              ...projectData,
              portada: e.target.value,
            });
          }}
        />

        <Button variant="contained" onClick={editProject}>
          EDIT
        </Button>
      </section>
    </>
  );
};
export default EditHomeProject;
