import {
  
  TextField,
} from "@mui/material";
import {  useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import MyButton from "./MyButton";
import CircularProgress from "@mui/material/CircularProgress";
import { InputReducer } from "../reducers/InputReducer";

const AddDirectorProject = (props) => {
  const [sending, setSending] = useState(false);
  const { setAdd } = props;
  const { getData, postData } = useQuery();

  const { setError } = props;
  const { id } = useParams();

  const addProject = async () => {
    if (
      inputData.titulo === "" ||
      inputData.cliente === "" ||
      inputData.video_mp4 === "" ||
      inputData.loop_mp4 === "" ||
      inputData.loop_mobile === "" ||
      inputData.portada === ""
    ) {
      setError({
        status: true,
        message: "You must fill all the fields",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const projectData = {
      director_id: parseInt(id),
      endpoint: "agregar-proyecto",
      titulo: inputData.titulo,
      cliente: inputData.cliente,
      video_mp4: inputData.video_mp4,
      loop_mp4: inputData.loop_mp4,
      loop_mobile: inputData.loop_mobile,
      portada: inputData.portada,
      destacado: inputData.destacado
    };
    const data = await postData("", projectData, localStorage.token);

    if (data.data.status === 201) {
      setError({ error: false });
      setSending(false);
      setAdd(false);
      return;
    }

    setError({ status: true, message: "Error inesperado", severity: "error" });
    setSending(false);
  };

  const [inputData, inputDispatch] = useReducer(InputReducer, {
    titulo: "",
    cliente: "",
    video_mp4: "",
    loop_mp4: "",
    loop_mobile: "",
    portada: "",
    destacado:false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };
  return (
    <>
      <section className="create shape">
        <>
          <h3>Agregar proyecto a director</h3>
          <p>Please fill all the fields below</p>

          <TextField
            id="Titulo"
            label="Titulo"
            variant="outlined"
            className="input_form"
            name="titulo"
            onChange={handleInputChange}
            value={inputData.titulo || ""}
          />
          <TextField
            id="cliente"
            label="Cliente"
            variant="outlined"
            className="input_form"
            name="cliente"
            onChange={handleInputChange}
            value={inputData.cliente || ""}
          />
          <TextField
            id="video_mp4"
            label="Video_mp4"
            variant="outlined"
            className="input_form"
            name="video_mp4"
            onChange={handleInputChange}
            value={inputData.video_mp4 || ""}
          />
          <TextField
            id="loop_mp4"
            label="Loop Desktop"
            variant="outlined"
            className="input_form"
            name="loop_mp4"
            onChange={handleInputChange}
            value={inputData.loop_mp4 || ""}
          />
          <TextField
            id="loop_mobile"
            label="Loop Mobile"
            variant="outlined"
            className="input_form"
            name="loop_mobile"
            onChange={handleInputChange}
            value={inputData.loop_mobile || ""}
          />
          <TextField
            id="portada"
            label="Portada"
            variant="outlined"
            className="input_form"
            name="portada"
            onChange={handleInputChange}
            value={inputData.portada || ""}
          />
          <div>
            <label> Proyecto destacado</label>
            <input type="checkbox" name="destacado" id="" value={inputData.destacado}
            onChange={(e)=>{
              inputDispatch({
                type: "CHANGE_FIELD",
                fieldName: "destacado",
                fieldValue: e.target.checked,
              });
            }}
            />
          </div>
          {!sending ? (
            <MyButton text="Set" onClick={addProject} className="action" />
          ) : (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          )}
        </>
      </section>
    </>
  );
};

export default AddDirectorProject;
