import { Alert, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import TopBar from "./TopBar";
import Navbar from "./Navbar";
import MyButton from "./MyButton";
import { InputReducer } from "../reducers/InputReducer";

const AddDirector = () => {
  const [superisores, setSupevisores] = useState([]);
  const [sending, setSending] = useState(false);

  const [inputData, inputDispatch] = useReducer(InputReducer, {});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };

  const { getData, postData } = useQuery();
  const navigate = useNavigate();
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });

  const createProject = async () => {
    if (inputData.nombre === "") {
      setError({
        status: true,
        message: "You must fill all the fields",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const newProjectData = {
      nombre: inputData.nombre,
      endpoint: "agregar-director",
    };
    const post = await postData("", newProjectData, localStorage.token);
    if (post.data.status === 201) {
        setError({
        error: true,
        severity: "success",
        message: "Director Agregado",
      });
      navigate(`/`);
    } 
    
  };

  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content">
        <h2 className="ml-20">Add new director</h2>
        <p className="ml-20">Please fill all the required information</p>
        {error.status && (
          <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
            {error.message}
          </Alert>
        )}
        <section className="create create_project shape">
          <TextField
            className="input_form"
            label="Nombre"
            name="nombre"
            variant="outlined"
            onChange={handleInputChange}
            value={inputData.nombre}
          />
          {!sending ? (
            <MyButton className="add" text="Add" onClick={createProject} />
          ) : (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          )}
        </section>
      </section>
    </>
  );
};

export default AddDirector;
