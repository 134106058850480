
//export const URL = "http://localhost/PuercoApi/api.php";
export const URL = "https://app.puerco.tv/api.php";

export const getDate = () => {
  let fechaHoy = new Date();

  // Obtener el mes, día y año
  let mes = fechaHoy.getMonth() + 1; // Los meses comienzan en 0, por lo que sumamos 1
  let dia = fechaHoy.getDate();
  let año = fechaHoy.getFullYear();

  // Formatear el mes y el día con ceros iniciales si son menores que 10
  if (mes < 10) {
    mes = "0" + mes;
  }
  if (dia < 10) {
    dia = "0" + dia;
  }

  // Obtener los últimos dos dígitos del año
  año = año.toString().slice(-2);
  const today = `${mes}/${dia}/${año}`;
  // Crear la cadena de fecha en el formato mm dd yy
  return today;
};

export const checkStartTime = (time) => {

  if(time && time.length<4){
    return false;
  }
  time = time.padStart(4, "0");
  const min = parseInt(time.slice(2));

  if (min >= 0 && min <= 59 && parseInt(time) < 2400 && time.length < 5) {
    return true;
  } else {
    return false;
  }
};

export const checkEndTime = (time) => {
  time = time.padStart(4, "0");
  const min = parseInt(time.slice(2));

  if (
    min >= 0 &&
    min <= 59 &&
    parseInt(time) > parseInt("0000") &&
    parseInt(time) < parseInt("2401") &&
    time.length < 5
  ) {
    return true;
  } else {
    return false;
  }
};

export const formatStartTime = (time) => {
  const part = time.split(":");
  const convertedTime = part[0] + part[1]; // Concatenar las partes de la hora y los minutos

  return convertedTime;
};

export const isValidEmail = (email) => {
  const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return regex.test(email);
};

export const isValidCompleteName = (name) => {
  const regex = /^[a-zA-Z]+\s[a-zA-Z]+$/;
  return regex.test(name);
};

export const dateToAMD =(date)=> {
  const originalDate = new Date(date);
  const dateObj = new Date(originalDate);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}
