import { useEffect, useReducer, useState } from "react";
import useQuery from "../hooks/useQuery";
import {
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Navbar from "../components/Navbar";
import TopBar from "../components/TopBar";
import MyButton from "../components/MyButton";
import { InputReducer } from "../reducers/InputReducer";

const AddContacto = () => {
  const [sending, setSending] = useState(false);
  const [view, setView] = useState({ add: true, delete: false });
  const [miembros, setMiembros] = useState([]);

  const initialState = {
    ubicacion: "",
    nombre: "",
    email: "",
    cargo: "",
    telf: "",
  };

  const [inputData, inputDispatch] = useReducer(InputReducer, initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };

  const [error, setError] = useState({ status: false, message: "" });
  const { deleteData, getData, postData } = useQuery();

  const register = async () => {
    if (
      inputData.ubicacion === "" ||
      inputData.nombre === "" ||
      inputData.email === "" ||
      inputData.telf === ""
    ) {
      setError({
        status: true,
        message: "You must enter all the fields",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const data = {
      ubicacion: inputData.ubicacion,
      cargo: inputData.cargo,
      nombre: inputData.nombre,
      email: inputData.email,
      telf: inputData.telf,
      endpoint: "agregar-contacto",
    };
    console.log(data)
    const response = await postData("", data, localStorage.token);
    if (response.data.status === 201) {
      setError({ status: true, message: "User created", severity: "success" });
      setSending(false);
    }
    setSending(false);
  };

  const getMembers = async () => {
    const members = await getData("?endpoint=contacto");
    console.log(members)
    if (members.data.data.length > 0) {
      setMiembros(members.data.data);
    }
  };

  useEffect(() => {
    setError({
      error: false,
    });
    if (view.delete) {
      getMembers();
    }
  }, [view]);

  const deleteUser = async () => {
    if (inputData.id === "") {
      setError({
        status: true,
        message: "Please select a user",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const data = {
      contacto_id: inputData.id,
      endpoint: "eliminar-contacto",
    };
    const response = await deleteData("", data, localStorage.token);
    if (response.error === true && response.data.response.status === 401) {
      setError({
        status: true,
        message: "You cannot delete yourself",
        severity: "error",
      });
    }
    if (response.data.status === 200) {
      setError({ status: true, message: "User Deleted", severity: "success" });
    }
    setSending(false);
  };

  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content">
        {view.add && (
          <>
            <h2 className="ml-20">Agregar Contacto</h2>
            <p className="ml-20">Please fill all the required information</p>
            <section className="create create_project shape">
              <TextField
                className="input_form"
                id="ubicacion"
                label="Ubicacion"
                variant="outlined"
                name="ubicacion"
                onChange={handleInputChange}
                value={inputData.ubicacion}
              />
              <TextField
                className="input_form"
                id="nombre"
                label="Nombre"
                variant="outlined"
                name="nombre"
                onChange={handleInputChange}
                value={inputData.nombre}
              />
              <TextField
                className="input_form"
                id="cargo"
                label="Cargo"
                variant="outlined"
                name="cargo"
                onChange={handleInputChange}
                value={inputData.cargo}
              />
              <TextField
                className="input_form"
                id="email"
                label="Email"
                variant="outlined"
                name="email"
                onChange={handleInputChange}
                value={inputData.email}
              />
              <TextField
                className="input_form"
                id="telf"
                label="Telf"
                variant="outlined"
                name="telf"
                onChange={handleInputChange}
                value={inputData.telf}
              />

              {!sending ? (
                <MyButton
                  onClick={register}
                  text="ADD CONTACTO"
                  className="action"
                />
              ) : (
                <CircularProgress
                  color="primary"
                  sx={{ alignSelf: "center" }}
                />
              )}
              {error.status && (
                <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
                  {error.message}
                </Alert>
              )}
            </section>
          </>
        )}
        {view.delete && (
          <>
            <h2 className="ml-20">Eliminar miembro</h2>
            <p className="ml-20">Please select a team member from the list</p>
            <section className="create create_project shape">
              <FormControl fullWidth className="input_form">
                <InputLabel>User</InputLabel>
                <Select
                  labelId="id"
                  value={inputData.id || ""}
                  name="id"
                  label="id"
                  onChange={handleInputChange}
                  id="id"
                >
                  {miembros?.map((dato) => {
                    return (
                      <MenuItem key={dato.ID} value={dato.ID}>
                        {dato.email}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {sending ? (
                <CircularProgress
                  color="primary"
                  sx={{ alignSelf: "center" }}
                />
              ) : (
                <MyButton
                  onClick={deleteUser}
                  text="DELETE CONTACTO"
                  className="action"
                />
              )}

              {error.status && (
                <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
                  {error.message}
                </Alert>
              )}
            </section>
          </>
        )}

        <section
          className="buttons"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "0 10%",
            color: "white",
          }}
        >
          <MyButton
            className="action"
            text="Agregar"
            onClick={() => {
              setView({ add: true });
            }}
          />

          <MyButton
            className="action"
            text="Eliminar"
            onClick={() => {
              setView({ delete: true });
            }}
          />
        </section>
      </section>
    </>
  );
};

export default AddContacto;
