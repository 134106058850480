import {
  Alert, 
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import TopBar from "../components/TopBar";
import Navbar from "../components/Navbar";
import MyButton from "../components/MyButton";
import { InputReducer } from "../reducers/InputReducer";

const EditDirector = () => {
  const { id } = useParams();
  const [director, setDirector] = useState();
  const [sending, setSending] = useState(false);

  const [inputData, inputDispatch] = useReducer(InputReducer, {});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };

  const { getData, putData } = useQuery();
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });

  const editProject = async () => {
    if (
      inputData.nombre === "" 
    ) {
      setError({
        status: true,
        message: "You must fill all the fields",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const isPuerco = inputData.hasProjects? 1 : 0;
    const newDirectorData = {
      director_id: id,
      hasProjects : isPuerco,
      endpoint: "actualizar-director",
      nombre: inputData.nombre
    };
    console.log(newDirectorData)
    const post = await putData("", newDirectorData, localStorage.token);
    if (post.data.status === 200) {
      setSending(false);
      setError({
        error: true,
        severity: "success",
        message: "Director Updated",
      });
   
    }
  };

  const getInitialData = async () => {
    const director = await getData(`?endpoint=director-id&id=${id}`, localStorage.token);
    setDirector(director.data.data.nombre);
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: "nombre",
      fieldValue: director.data.data.nombre,
    });
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: "hasProjects",
      fieldValue: director.data.data.hasProjects==1,
    });
    
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      <TopBar />
      <Navbar />
      <section className="content">
        <h2 className="ml-20">Edit Director</h2>
        <p className="ml-20">Please fill all the required information</p>
        <section className="create create_project shape">
          <TextField
            className="input_form"
            label="Director Name"
            name="nombre"
            variant="outlined"
            onChange={handleInputChange}
            value={inputData.nombre || ""}
          />
              <div style={{ margin: "20px 0" }}>
                <label> Tiene proyectos en Puerco</label>
                <input
                  type="checkbox"
                  name="destacado"
                  id=""
                  value={inputData.hasProjects}
                  checked={inputData.hasProjects}
                  onChange={(e) => {
                    inputDispatch({
                      type: "CHANGE_FIELD",
                      fieldName: "hasProjects",
                      fieldValue: e.target.checked,
                    });
                  }}
                />
              </div>
         
          {!sending ? (
            <MyButton className="add" text="Edit" onClick={editProject} />
          ) : (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          )}
           {error.error && (
          <Alert severity={error.severity} sx={{ marginTop: "1rem" }}>
            {error.message}
          </Alert>
        )}
        </section>
      </section>
    </>
  );
};

export default EditDirector;
