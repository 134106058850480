import { Button } from "@mui/material";
import { useState } from "react";
import useQuery from "../hooks/useQuery";
import Skeleton from "@mui/material/Skeleton";
import DeleteIcon from "@mui/icons-material/Delete";

const EmployeesGrid = (props) => {
  const {
    projects,
    setProjects,
    projectId,
    setProjectId,
    setError,
    setLoading,
    clase,
  } = props;
  const { add, edit, setEdit, setEmployeeId, loading, getInitialData } = props;

  const { deleteData } = useQuery();

  const deleteRow = async (id) => {
    const data = {
      proyecto_id: parseInt(id),
      endpoint: "eliminar-proyecto",
    };
    const deletePost = await deleteData("", data, localStorage.token);

    if (deletePost.error === false) {
      setLoading(true);
      getInitialData();
    }
  };

  return (
    <section className="data" title="test">
      <section className={`info ${clase || ""}`}>
        <h2 className="header_title">Titulo</h2>
        <h2 className="header_title">Cliente</h2>
        {clase === "info-trabajo_home" && (
          <h2 className="header_title">Director</h2>
        )}
        <h2 className="header_title">Video</h2>
        <h2 className="header_title">Loop Desktop</h2>
        <h2 className="header_title">Loop Mobile</h2>
        <h2 className="header_title">Portada</h2>
        <h2 className="header_title">Edit</h2>
        <h2 className="header_title">Delete</h2>
      </section>

      {projects.map((data) => {
        return (
          <section
            className={`info ${clase || ""}`}
            key={projects.indexOf(data)}
          >
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h2 className="info_name">{data.titulo}</h2>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h2 className="info_name">{data.cliente || data.director}</h2>
            )}
            {clase === "info-trabajo_home" && (
              <>
                {loading ? (
                  <Skeleton
                    variant="text"
                    sx={{
                      fontSize: "1rem",
                      height: "30px",
                      alignSelf: "center",
                      padding: "0 5px",
                    }}
                  />
                ) : (
                  <h2 className="info_name">{data.director}</h2>
                )}
              </>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h3>{data.video_mp4}</h3>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h3>{data.loop_mp4}</h3>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h3>{data.loop_mobile}</h3>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <h3>{data.portada}</h3>
            )}

            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 5px",
                }}
              />
            ) : (
              <Button
                variant="outlined"
                id={data.proyecto_id || data.trabajo_id}
                size="small"
                onClick={(e) => {
                  setProjectId(e.target.id);
                  setEdit(true);
                }}
              >
                EDIT
              </Button>
            )}
            {loading ? (
              <Skeleton
                variant="text"
                sx={{
                  fontSize: "1rem",
                  height: "30px",
                  alignSelf: "center",
                  padding: "0 10px",
                }}
              />
            ) : (
              <DeleteIcon
                id={data.proyecto_id}
                onClick={(e) => {
                  let id_to_delete;
                  if (e.target.tagName === "path") {
                    id_to_delete = e.target.parentNode.id;
                  } else {
                    id_to_delete = e.target.id;
                  }
                  deleteRow(id_to_delete);
                }}
                sx={{ color: "tomato" }}
                className="delete_icon"
              />
            )}
          </section>
        );
      })}
    </section>
  );
};

export default EmployeesGrid;
